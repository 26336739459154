<template>
  <section class="help container">
    <h1 class="text__center padding__md">Helpful Tips</h1>
    <section class="padding__sm max-width">
      <p
        class="mb-15"
      >Search for tips on using the website, helpful hints, and definitions of terms that we use across the site</p>
      <div class="search--bar">
        <input type="text" v-model="search" placeholder="search for tips" />
        <div class="btn__search">
          <font-awesome-icon icon="search" size="1x" />
        </div>
      </div>
    </section>
    <section class="help-section" v-for="group in filteredHelp" :key="group.name">
      <div class="header__sticky">
        <div class="tile-heading">
          <h2>{{ group.name }}</h2>
          <hr />
        </div>
      </div>
      <ul class="help-list">
        <HelpItem
          v-for="(item, index) in group.items"
          :key="`${group.name}-${index}`"
          :question="item.q"
          :answer="item.a"
        />
      </ul>
    </section>
    <section class="help-other">
      <p class="text__center padding__md text__bold">
        Didn't find the answer to your question? Reach out to us and we will help you.
        <br />
        <router-link to="/contact-us" class="link__none text__bold">Contact Us</router-link>
      </p>
    </section>
  </section>
</template>

<script>
import HelpItem from "./HelpItem";
export default {
  components: {
    HelpItem
  },
  metaInfo: {
    title: "Help | WOW"
  },
  data() {
    return {
      search: "",
      help: [
        {
          name: "Orders",
          items: [
            {
              q: "How do I order?",
              a: `Click on "New Order" or "Start Order" where you find a button. From there you will enter details about the order and you may select a favorite order if you have one to use preset product selections and other order details. You will be taken to a page to select products for the order. Once all products are selected click on the cart button at the top right and click "Continue to Confirm". Confirm all information is accurate and complete the information at the bottom of the page and click "Submit Order".`
            },
            {
              q: "How can I see my past orders?",
              a: `If you are signed in, you can view the last four updated orders on the home page. By clicking "See More" you will be able to view, search, edit, and export your orders. You may also go to your account page to view the last four updated orders. Click "See More" to view, search, export, and edit your orders.`
            },
            {
              q: "Can I check on order progress?",
              a: `Yes! On every order tile, you will see an order status on the top left. By clicking on the "View" button you can view more details about that order and possibly edit the order depending the status. To get more information respond to the order email you received when you submitted the order.`
            },
            {
              q: "Can I get tracking information for a product I ordered?",
              a: `For external products--products that WOW does not keep in-house--we may be able to enter a link to view tracking information for a given product. On the order confirm screen or order item screen there may be a link to view tracking information for that product. Some orders may not have tracking information, this is something that our supplier provides at their discretion.`
            },
            {
              q: "Can I delete or update an order?",
              a: `You can delete or update an order if the order status is still "Started". After that point, please contact the WOW department by responding to your order email. They may be able to delete the order if they have not yet ordered or shipped any of the products.`
            },
            {
              q: "What if I have a question about an order?",
              a: `Please respond to the order email you received when you submitted the order. If you have not submitted the order yet, please read through this FAQ to see if your question is addressed. Otherwise please reach out using the contact form.`
            },
            {
              q: "Can I export all of my orders?",
              a: `Yes! When viewing your orders you may click "Export" at the top right to export all of the orders you currently have available to view (depending on the filters applied). Choose to download all data, all order data, or all order item (product) data.`
            }
          ]
        },
        {
          name: "Website",
          items: [
            {
              q: "Are shipping costs included in the price of a product?",
              a: `Yes! Shipping costs are included in the price of a product and the total cost of an order. However, taxes and fees may apply in addition to the product cost or total cost depending on who is receiving the gifts.`
            },
            {
              q: "How do I make a product suggestion?",
              a: `We welcome product suggestions from you! You must be signed in to submit a suggestion. On the home page you can scroll to the bottom and fill out the product suggestion short form or you may visit the contact page to fill out the form. Be sure to select "Product Suggestion" as the topic.`
            },
            {
              q: "What do I do if I am having trouble signing in?",
              a: `We have a page dedicated to log in issues. Please visit the contact page and click on the link in the text or open the menu and click on the link just under the "Sign In" button. Please fill out the form with all applicable information and we will look into the issue.`
            },
            {
              q: "What is RESPA?",
              a: `Respa rules govern how much in gifts can be given to borrowers or referral partners. You are permitted to gift up to $25 per recipient. The limit does not include shipping, taxes, or customization fees.`
            },
            {
              q: "What is a billing user?",
              a: `The billing user is the person who accounting will charge for the order.`
            },
            {
              q: "What is a batch order?",
              a: `A batch order is an list of orders that you can create, update, and submit all at once. We recommend this if you have a list of borrowers from Encompass that you are wanting to submit orders for all at once and have similar product selections for each of them.`
            },
            {
              q: "What is award balance?",
              a: `Your award balance is an amount of credit that can be used to offset the total cost of an order.`
            },
            {
              q: "What is balance due versus total cost?",
              a: `Total cost is the total amount of all product costs for an order prior to applying award balance. Balance due is the total cost less any award balance applied.`
            },
            {
              q: `What is "shipping phone"?`,
              a: `Shipping phone is the phone number of the recipient so that any delivery questions can be directed to the recipient. In some cases you may want to use your phone number, please be advised you will be getting any calls related to delivery questions if you choose to use your phone number.`
            },
            {
              q: "What is a CSV?",
              a: `CSV stands for Comma Separated Values. It is a common file type used to import data without all of the unnecessary trimmings of excel or other services.`
            },
            {
              q: "What are the product statuses?",
              a: `Started means that the order has been created but a WOW representative will not be looking at it. Submitted means that the order has been submitted for fulfillment by a WOW representative....`
            }
          ]
        },
        {
          name: "Favorites",
          items: [
            {
              q: "What is a favorite order?",
              a: `A favorite order is a set of products, product customizations, and order details that you can use as a starting point for new orders. We highly recommend this if you place similar orders very frequently using the same products and thank you messages or comments.`
            },
            {
              q: "How do I create a favorite order?",
              a: `To create a favorite order, go through the order process as normal on the order you are wanting to reuse. This include entering the order details, selecting and customization products, and filling out all confirmation details. Once submitted, find this order in your past orders and scroll to the bottom of the confirm screen. You will see a tile for marking this order as a favorite and another tile for naming the favorite and ranking it. The top four ranked favorited will appear on your home page and account page.`
            },
            {
              q: "How do I remove or update a favorite order?",
              a: `You can remove a favorite by visiting the order in question, scrolling to the favorite section on the confirm screen and clicking "Unfavorite this Order". You may update the product selection, product customizations, and order details by contacting your WOW representative with the link to the order. If the order has been completed or any product has been submitted they will not be able to update it. In that case you should create a new favorite order with updates made.`
            },
            {
              q: "How do I use a favorite order?",
              a: `On the home page, click on the "Start Order" button of the favorite you would like to use. You may also start a new order and search for a favorite to use at the bottom of the order details screen. Click on one to select it as the favorite to use. This will automatically set up the products, product customizations, and confirmation details on this order. You can edit or remove any of these settings if you want to change them on this order. You may even mark this new order as a favorite once you submit it so you can reuse your new product selections, product customizations, and or confirmation details.`
            }
          ]
        },
        {
          name: "Batch Orders",
          items: [
            {
              q: "What is a batch order?",
              a: `A batch order is an list of orders that you can create, update, and submit all at once. We recommend this if you have a list of borrowers from Encompass that you are wanting to submit orders for all at once and have similar product selections for each of them.`
            },
            {
              q: "How do I get access to the batch order form?",
              a: `A WOW representative must give you access to the batch form as it does require some training. On the menu you may click the "Batch Order" button to be taken to a pre-filled contact form to submit. You may also fill out the batch access form on your account page or visit the contact page and select "Batch Order Access" as the topic. A WOW representative will schedule a meeting with you to go over creating and submitting batch orders.`
            },
            {
              q: "What am I entering when I create a batch order?",
              a: `The first part of creating a batch order is entering some comments for the entire batch order as well as setting some defaults that will be used for each order added to the batch order. This saves you a lot of time if you want to bill to the same person, use the same thank you note, use the same billing method, or have the same comments for each order added to the batch order.`
            },
            {
              q:
                "Can I upload data to the batch order form to create several orders at once?",
              a: `Yes! We have a template for adding a large amount of orders at once. Once you have entered the batch order details and are at the batch items screen, click on the "Import Sheet" button to download the template for creating orders. Carefully enter the data on the excel spreadsheet, following the validation in place in excel. Once you are done adding data, click File, Save As, then save to your preferred destination as a CSV (comma separated value) file. In the WOW website click on "Import CSV", navigate to the file destination, and select it to upload the data.`
            },
            {
              q: "Can I upload more data to the batch order?",
              a: `Yes! At any point you can upload another CSV file with order information to add more orders. CAUTION: we do not remove duplicate orders so be careful not to upload data that has already been entered.`
            },
            {
              q: "How do I a single order to the batch order?",
              a: `Scroll to the bottom of the batch screen and click "Add An Order". Fill out the form and click "Submit".`
            },
            {
              q: "How do I add products to an order?",
              a: `Once you have added orders you can click on a single order to edit the order and search, customize, and select products on the sidebar for that order. Click another order from the list to switch to that order.`
            },
            {
              q: "How do I add the same product selections to multiple orders?",
              a: `Set up your product(s) in the Order Products Template section. Once set up, click on "Use Template" to add the product template to the highlighted order. You can then click on the order from the list to edit the product selection if you wish. You may also alter the product template and click "Use Template" again to add more products. `
            },
            {
              q: "How do I edit an order?",
              a: `Click on the order from the list to open the sidebar for that order. Edit the order details, product selection or customizations, and update the confirmation details. Be sure to click "Save" to save the changes you made.`
            },
            {
              q: "How do I delete an order from the batch order?",
              a: `You may click on the delete button for a given order in the list to delete it.`
            },
            {
              q: "Can I delete multiple orders from the batch order?",
              a: `Yes! By checking the box to the left of the order you want to delete you add it to a list of orders to delete. Check as many as you like then click "Delete Orders" at the top right. You may select or unselect all orders by checking the box to the left of the order headers, just below "Import CSV".`
            },
            {
              q: "How do I delete the entire batch order?",
              a: `To delete the batch order and all of its associated orders, scroll to the top right of the batch items page and click "Delete". Confirm you would like to delete to proceed.`
            },
            {
              q:
                "How do I submit the batch order and all of the associated orders?",
              a: `Once you are done entering all order data, click on the shopping cart button. This will show the overall batch order details which you can edit and save. If all orders are properly set up you will be able to submit or you will be prompted to finish setting up the orders. Any unfinished orders will have a red X next to them and ready orders are marked with a green check.`
            },
            {
              q:
                "Why is the balance due is the same as the total cost when I chose to use my award balance?",
              a: `The award balance will be applied to individual orders by a WOW representative as they complete them. This helps with keeping your award balance from being over-applied.`
            }
          ]
        },
        {
          name: "Account",
          items: [
            {
              q: "Why was I charged more than the total cost of my order?",
              a: `For internal employee orders, we are required by law to charge taxes as the gifts are considered income. The rate which is charged is dependant on the recipient's income.`
            },
            {
              q: "How can my team member bill to my account?",
              a: `Visit your account page and look for the Delegated User's tile. These users are people that you select to be able to view your orders, bill orders to your account, and use your award balance for orders billed to you. Add or remove people as necessary.`
            },
            {
              q: "What will change my award balance?",
              a: `Each month we offer awards for the person that ordered the most on the store and we also offer an award to a randomly selected user that was new to WOW in the last month. Awards are for $25.`
            },
            {
              q: "What are common messages?",
              a: `Common messages are responses that can be used to fill in some text fields on the website. For example, if you find yourself entering the same thank you message a lot, create a common message to have an option to pre-fill with that message any time you like. Any text fields with a dark box next to them have the option to be pre-filled.`
            },
            {
              q: "How do I use my common message?",
              a: `Throughout the site there are text fields with dark boxes to the right of them. Click on that box to view your common message names and select one to use. This will pre-fill the text field with the message for you to keep or edit.`
            },
            {
              q: "Can I share my common messages with others?",
              a: `Yes. On your account page, scroll to the common messages tile and in the top right of the tile you will see a download icon. Click that to download an excel spreadsheet of your messages. Feel free to share this with anyone to help them create their own messages.`
            },
            {
              q: "Can I update my email on the website?",
              a: `By default, no you cannot update your email. This is because your email must match your Flat Branch email exactly. If it has changed please reach out to a WOW representative via the contact form."`
            },
            {
              q: "How can I set up an account for my team's marketing budget?",
              a: `Reach out to a WOW representative via the contact form to have them create the account and add your team members as delegated users.`
            }
          ]
        }
      ]
    };
  },
  computed: {
    filteredHelp() {
      if (!this.search) {
        return this.help;
      }
      return this.help
        .map(section => {
          const info = section.items
            .map(item => {
              if (
                item.q.toLowerCase().includes(this.search.toLowerCase()) ||
                item.a.toLowerCase().includes(this.search.toLowerCase())
              ) {
                return item;
              }
              return null;
            })
            .filter(item => item);
          if (info.length > 0) {
            return {
              name: section.name,
              items: info
            };
          }
          return null;
        })
        .filter(sect => sect);
    }
  }
};
</script>

<style scoped lang="scss">
.header__sticky {
  background-color: lighten($frost, 20%);
}
.max-width {
  max-width: 650px;
  margin: auto;
}
</style>